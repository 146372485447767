import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

const slider = '#js-hero-slider';

// Swiper init
const heroSlider = new Swiper(slider, {
    modules: [Navigation, Pagination, Autoplay],
    effect: 'fade',
    slidesPerView: 1,
    loop: true,
    autoplay: {
        delay: 7000,
    },
    pagination: {
        el: '.js-hero-slider-pagination',
        clickable: true,
        bulletClass: 'hero-slider-pagination__item',
        bulletActiveClass: 'is-active',
    },
    navigation: {
        nextEl: '.js-hero-nav-next',
        prevEl: '.js-hero-nav-prev',
    },
});
